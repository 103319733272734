import get from 'lodash/get';

function getEnvVariable(name) {
  return get(window, `_env_.${name}`) || process.env[name];
}

const AUTH_TOKEN = 'auth-token';
const API_URL = 'https://api-qa1.installersnetwork.com';
const MANDI_API_URL = getEnvVariable('REACT_APP_MANDI_API_URL');
const MANDI_XAPI_KEY = getEnvVariable('REACT_APP_MANDI_XAPI_KEY');
const SUPER_ADMIN = 'admin';
const MANDIDEV = 'mandiDev';
const IS_SUPER_ADMIN = () => Boolean(localStorage.getItem('isSuperAdmin'));
const IS_MANDI_DEV = () => Boolean(localStorage.getItem('isMandiDev'));
const GET_AUTH_TOKEN = () => localStorage.getItem(AUTH_TOKEN);
const PERSISTENT_USER = 'persistent-user';
const GRAPHQL_URL = getEnvVariable('REACT_APP_GRAPHQL_URL');
const OLD_VENDOR_ADMIN_URL = getEnvVariable('REACT_APP_OLD_VENDOR_ADMIN_URL');
const TOKEN = () => Boolean(localStorage.getItem(AUTH_TOKEN));
const LOGIN_USER = () => localStorage.getItem(PERSISTENT_USER);
const SEARCH_MONTHS = 6;

export {
  API_URL,
  MANDI_API_URL,
  MANDI_XAPI_KEY,
  AUTH_TOKEN,
  GET_AUTH_TOKEN,
  GRAPHQL_URL,
  IS_SUPER_ADMIN,
  LOGIN_USER,
  OLD_VENDOR_ADMIN_URL,
  PERSISTENT_USER,
  SUPER_ADMIN,
  TOKEN,
  MANDIDEV,
  IS_MANDI_DEV,
  SEARCH_MONTHS,
};
