export default theme => ({
  loadingContainer: {
    display: 'flex',
    height: '60vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  background: {
    background: '#eaeaea',
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paperModal: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  firstColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2),
    },
  },
  box: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  underline: {
    borderBottom: '1px solid #ccc',
  },
  paddingTop: {
    paddingTop: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  label: {
    color: '#999999',
  },
  print: {
    fontSize: '16px',
    color: '#3e7697',
    letterSpacing: 0,
    textAlign: 'right',
  },
  relatedOrderWrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  willCallAlert: {
    marginTop: theme.spacing(4),
  },
});
