import React, { useState } from 'react';
import {
  object,
} from 'prop-types';
import compose from 'lodash/flowRight';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  container: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  icon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    color: 'inherit',
    width: '100%',
  },
  input: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
});

const Search = ({ classes }) => {
  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState('');

  const routeToSearch = () => {
    const pathname = '/all-jobs';
    const search = `?searchTerm=${searchTerm}`;
    const historyParams = searchTerm ? { pathname, search } : { pathname };

    history.push(historyParams);
  };

  const updateSearchTerm = ({ target }) => setSearchTerm(target.value);
  const handleKeyDown = ({ key }) => {
    if (key === 'Enter') {
      routeToSearch();
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.icon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search…"
        classes={{
          root: classes.root,
          input: classes.input,
        }}
        onChange={updateSearchTerm}
        onKeyDown={handleKeyDown}
        value={searchTerm}
      />
    </div>
  );
};

Search.propTypes = {
  classes: object.isRequired,
};

const EnhancedSearch = compose(withStyles(styles))(Search);

export default EnhancedSearch;
