import React from 'react';
import {
  string,
  bool,
  node,
  func,
  object,
} from 'prop-types';
import isNil from 'lodash/isNil';
import classNames from 'classnames';
import ErrorIcon from '@material-ui/icons/Error';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '../index';

import styles from './styles';

const useStyles = makeStyles(styles);

function LoadingDialog(props) {
  const {
    isOpen,
    isLoading,
    error,
    isDialogSubmitButtonDisabled,
    onDialogSubmit,
    onDialogClose,
    onErrorTryAgain,
    dialogLoadingText,
    dialogSubmitButtonText,
    dialogTitleText,
    dialogContent,
    fullScreen,
  } = props;
  const classes = useStyles();
  const hasError = !isNil(error);

  return (
    <Dialog open={isOpen} fullScreen={fullScreen}>
      {isLoading && (
        <div className={classes.loadingOverlay}>
          <Loader />
          <Typography className={classes.loadingText}>
            {dialogLoadingText}
          </Typography>
        </div>
      )}
      {hasError && (
        <div className={classes.loadingOverlay}>
          <ErrorIcon color="error" />
          <Typography className={classes.loadingText}>
                An unexpected error has occurred.
          </Typography>
          <Typography>
                Please try again.
          </Typography>
        </div>
      )}
      <DialogTitle className={classNames(classes.bringToOverlay, classes.titleText)}>{dialogTitleText}</DialogTitle>
      <DialogContent>
        {dialogContent}
      </DialogContent>
      <DialogActions className={hasError ? classes.bringToOverlay : ''}>
        <>
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={onDialogClose}
            className={classes.buttonTextTransform}
          >
                Cancel
          </Button>
          {hasError
            ? (
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={onErrorTryAgain}
                className={classes.buttonTextTransform}
              >
                Try Again
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={onDialogSubmit}
                disabled={isDialogSubmitButtonDisabled}
                className={classes.buttonTextTransform}
              >
                {dialogSubmitButtonText}
              </Button>
            )}
        </>
      </DialogActions>
    </Dialog>
  );
}

LoadingDialog.defaultProps = {
  /** This prop can be undefined if there's nothing happening/when initializing component. */
  error: null,
};

LoadingDialog.propTypes = {
  /** When true The dialog will show itself */
  isOpen: bool.isRequired,
  /** When true The dialog will show a loading spinner, hide the dialog content and buttons */
  isLoading: bool.isRequired,
  /** Error object that the dialog will use to show an error message indicating the request failed */
  error: object,
  /** When true the submit button will be disabled */
  isDialogSubmitButtonDisabled: bool.isRequired,
  /** The title of the dialog */
  dialogTitleText: string.isRequired,
  /** The text for the submit button */
  dialogSubmitButtonText: string.isRequired,
  /** The text to be shown with the loading spinner */
  dialogLoadingText: string.isRequired,
  /** The primary content of the dialog */
  dialogContent: node.isRequired,
  /** The callback fired when the dialog submit button is pressed */
  onDialogSubmit: func.isRequired,
  /** The callback fired when the dialog close button is pressed */
  onDialogClose: func.isRequired,
  /** The callback fired when the dialog's error try again button is pressed */
  onErrorTryAgain: func.isRequired,
  /** Allows the dialog to go into full screenmode or not using the `withMobileDialog` */
  fullScreen: bool.isRequired,
};

export default LoadingDialog;
