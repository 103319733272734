import React from 'react';
import { MuiThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import UnscheduledJobs from '../components/UnscheduledJobs';
import HelpfulLinks from '../components/HelpfulLinks';
import Login from '../components/Login';
import JobsList from '../components/JobsList';
import OrderDetails from '../components/OrderDetails';
import UnassignedJobs from '../components/UnassignedJobs';
import UnreadNoteJobs from '../components/UnreadNoteJobs';
import Settings from '../components/Settings';
import JobEditor from '../components/JobEditor';
import NoMatch from '../components/NoMatch';
import InstallerPickup from '../components/InstallerPickup';
import ChangedJobs from '../components/ChangedJobs';

import ProtectedRoute from './ProtectedRoute';
import Header from './Header';
import { IS_SUPER_ADMIN, IS_MANDI_DEV } from '../constants';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#F96302',
      dark: '#C14C00',
      grey: '#eaeaea',
      lightGrey: '#f3f3f3',
      green: green.A700,
      red: '#d6422f',
      link: '#3e7697',
      darkGrey: '#8C8C8C',
    },
  },
  typography: { useNextVariants: true },
  text: {
    boldWeight: 600,
  },
  border: '1px solid #ccc',
});

const styles = () => ({
  container: {
    height: '100vh',
    backgroundColor: '#eaeaea',
  },
});

const useStyle = makeStyles(styles);

function App() {
  const classes = useStyle();

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.container}>
        <Router>
          <Header />
          <Switch>
            <ProtectedRoute exact path="/">
              <UnscheduledJobs />
            </ProtectedRoute>
            <Route path="/login">
              <Login />
            </Route>
            <ProtectedRoute match path="/all-jobs">
              <JobsList />
            </ProtectedRoute>
            <ProtectedRoute path="/job/:id">
              <OrderDetails />
            </ProtectedRoute>
            <ProtectedRoute path="/unassigned-jobs">
              <UnassignedJobs />
            </ProtectedRoute>
            <ProtectedRoute path="/unread-note-jobs">
              <UnreadNoteJobs />
            </ProtectedRoute>
            <ProtectedRoute path="/installer-pickup">
              <InstallerPickup />
            </ProtectedRoute>
            <ProtectedRoute path="/changed-jobs">
              <ChangedJobs />
            </ProtectedRoute>
            {IS_SUPER_ADMIN() && (
              <ProtectedRoute path="/settings">
                <Settings />
              </ProtectedRoute>
            )}
            {IS_MANDI_DEV() && (
              <ProtectedRoute path="/job-editor">
                <JobEditor />
              </ProtectedRoute>
            )}
            <Route path="/helpful-links">
              <HelpfulLinks />
            </Route>
            <ProtectedRoute>
              <NoMatch />
            </ProtectedRoute>
          </Switch>
        </Router>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
