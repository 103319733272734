import React from 'react';
import { useQuery } from '@apollo/client';
import { object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import { Loader, Error } from 'shared/components';
import { ignoreTimeZoneAndConvertDate } from 'shared/utilities';
import { GET_JOB_BY_ID } from 'apollo/queries';
import { useParams } from 'react-router-dom';
import OrderHeader from './OrderHeader';
import ContactInformation from './ContactInformation';
import PayoutDetails from './PayoutDetails';
import ServiceInformation from './ServiceInformation';
import JobInformation from './JobInformation';
import Scheduling from './Scheduling';
import TechAssignment from './TechAssignment';
import JobNotes from './JobNotes';
import RelatedOrders from './RelatedOrders';
import styles from './styles';
import { getJobWillCallMsg } from '../../shared/utilities';
import Alert from '../../shared/components/Alert';
import checkIfJobIsLaborOnlyPo from '../../shared/utilities/checkIfJobIsLaborOnlyPo';

function OrderDetails({ classes }) {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_JOB_BY_ID, {
    variables: {
      id,
    },
  });

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <Loader />
      </div>
    );
  }

  if (error) {
    return <Error error={error} />;
  }

  const {
    id: jobId,
    status,
    statusHistory = [],
    customer,
    address,
    serviceType,
    order = {},
    initialPricingResult,
    pricingResult,
    changeOrder = null,
    storeNumber,
    siteCode,
    notes,
    vendor,
    relatedJobs = [],
    preferredAppointmentTimes = [],
    appointments = [],
    availableTechnicians = [],
    availableTimeslots: rawAvailableTimeslots = [],
    pricingAgreementId,
    source,
  } = data.jobById;

  const willCallMsg = getJobWillCallMsg(data.jobById);
  const isLaborOnlyPo = source.toLowerCase() === 'instore' && checkIfJobIsLaborOnlyPo(notes);

  const jobAppointment = appointments[0] || {};
  const availableTimeslots = rawAvailableTimeslots.map(it => ({
    dateStart: ignoreTimeZoneAndConvertDate(it.dateStart),
    dateEnd: ignoreTimeZoneAndConvertDate(it.dateEnd),
  }));

  const orderHeaderProps = {
    jobId,
    orderNumber: order.orderNumber,
    serviceType,
  };

  const isInstallJob = serviceType === 'installation';

  const jobInformationProps = {
    status,
    address,
    order,
    storeNumber,
    siteCode,
    source,
  };

  const isDeadPool = () => {
    let result = false;
    if (pricingResult && pricingResult.charges) {
      pricingResult.charges.forEach((charge) => {
        if (charge.specId === 'deadpool') {
          result = true;
        }
      });
    }
    return result;
  };

  const techAssignmentProps = {
    jobId: id,
    appointmentId: jobAppointment.id,
    assignedTechnicians: jobAppointment.technicians,
    availableTechnicians,
    jobStatus: status,
  };

  const schedulingProps = {
    scheduledStart: jobAppointment.dateStart,
    scheduledEnd: jobAppointment.dateEnd,
    jobId: id,
    appointmentId: jobAppointment.id,
    preferredAppointmentTimes,
    availableTimeslots,
    jobStatus: status,
    statusHistory,
    vendorMVNumber: get(vendor, 'mvNumber', ''),
    serviceType,
    source,
    willCallMsg,
    isDeadPool: isDeadPool(),
  };

  const jobNotesProps = {
    allNotes: notes,
    jobId,
    status,
    statusHistory,
    source,
  };

  const relatedOrdersProps = {
    relatedJobs,
  };

  const payoutDetailsProps = {
    pricingResult,
    initialPricingResult,
    changeOrder,
    pricingAgreementId,
    isLaborOnlyPo,
  };

  return (
    <div className={classes.background}>
      <div className={classes.root}>
        <Alert
          className={classes.willCallAlert}
          color="warning"
          message={willCallMsg}
        />
        <div className={classes.row}>
          <OrderHeader {...orderHeaderProps} />
        </div>
        <div className={classes.row}>
          <div className={classes.firstColumn}>
            <JobInformation {...jobInformationProps} />
          </div>
          <div className={classes.column}>
            <ContactInformation customer={customer} />
            {(status !== 'pending-products' || isInstallJob) && (
              <React.Fragment>
                <Scheduling {...schedulingProps} />
                <TechAssignment {...techAssignmentProps} />
              </React.Fragment>
            )}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.firstColumn}>
            <ServiceInformation job={data.jobById} />
          </div>
          <div className={classes.column}>
            <PayoutDetails {...payoutDetailsProps} />
          </div>
        </div>
        <JobNotes {...jobNotesProps} />
        <div className={classes.relatedOrderWrapper}>
          <RelatedOrders {...relatedOrdersProps} />
        </div>
      </div>
    </div>
  );
}

OrderDetails.propTypes = {
  classes: object.isRequired,
};

export default withStyles(styles)(OrderDetails);
