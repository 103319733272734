import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import sortBy from 'lodash/fp/sortBy';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { add } from 'date-fns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MultiSelect from '../../../shared/components/Multiselect';
import CheckGroup from '../../../shared/components/CheckboxesGroup';
import { LOGIN_USER, MANDI_API_URL, MANDI_XAPI_KEY } from '../../../constants';
import { getVendorsByUser } from '../queries';
import client from '../../../apollo';
import {
  InitialFromDate,
  InitialToDate,
  InitialLocations,
  InitialStatuses,
  InitialServiceTypes,
  InitialSites,
} from '../constants';

const Sidebar = (
  {
    classes,
    onApplyClicked,
  },
) => {
  const [fromDate, setFromDate] = useState(InitialFromDate);
  const [toDate, setToDate] = useState(InitialToDate);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [locationList, setLocationList] = useState(InitialLocations);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [statusList, setStatusList] = useState(Object.keys(InitialStatuses));
  const [selectedServiceTypes, setSelectedServiceTypes] = useState(InitialServiceTypes);
  const [selectedSites, setSelectedSites] = useState(InitialSites);
  const [applyButtonEnabled, setApplyButtonEnabled] = useState(true);
  const [vendorsList, setVendorsList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('All');

  const selectedVendorsFilter = selectedVendor === 'All' ? vendorsList.map(vendor => vendor.mvNumber) : [selectedVendor];
  const siteCodesFilter = Object.entries(selectedSites).filter(([, value]) => value === true).map(([key]) => key);
  const serviceTypesFilter = Object.entries(selectedServiceTypes).filter(([, value]) => value === true).map(([key]) => key.toLowerCase());
  const statusesFilter = Object.entries(InitialStatuses).filter(([key]) => selectedStatuses.includes(key)).map(([, value]) => value);


  useEffect(() => {
    client.query({ query: getVendorsByUser, variables: { ldap: `${LOGIN_USER()}` } })
      .then((outerResult) => {
        const mvNumbers = outerResult.data.vendorsByUser || [];
        if (mvNumbers.length > 1) {
          const config = {
            'x-api-key': `${MANDI_XAPI_KEY}`,
          };
          const data = { mvNumbers: mvNumbers };
          axios.post(`${MANDI_API_URL}/api/vendors/getvendornames`, data, config)
            .then((innerResult) => {
              const vendorsWithNames = flow(
                get('data'),
                sortBy('vendorName'),
              )(innerResult);
              setVendorsList(vendorsWithNames);
              setSelectedVendor('All');
            }).catch((error) => {
              // eslint-disable-next-line no-console
              console.log('error: ', error);
              setVendorsList([]);
              setSelectedVendor(null);
            });
        }
      });
  }, []);


  const dateFns = new DateFnsUtils();

  const onLocationChange = (items) => {
    if (items.find(item => item === 'All') !== undefined || items.length === Object.keys(InitialLocations).length - 1)
    {
      const locationArray = InitialLocations.filter(item => item !== 'All');
      setLocationList(['None'].concat(locationArray));
      setSelectedLocations(locationArray);
      return;
    }
    setLocationList(InitialLocations);
    if (items.find(item => item === 'None') !== undefined)
    {
      setSelectedLocations([]);
      return;
    }
    setSelectedLocations(items);
  };

  const onVendorChange = (event) => {
    setSelectedVendor(event.target.value);
  };

  const onStatusChange = (items) => {
    if (items.find(item => item === 'All') !== undefined || items.length === Object.keys(InitialStatuses).length - 1)
    {
      const statusArray = Object.keys(InitialStatuses).filter(item => item !== 'All');
      setStatusList(['None'].concat(statusArray));
      setSelectedStatuses(statusArray);
      return;
    }
    if (items.find(item => item === 'None') !== undefined)
    {
      setStatusList(Object.keys(InitialStatuses));
      setSelectedStatuses([]);
      return;
    }
    setStatusList(Object.keys(InitialStatuses));
    setSelectedStatuses(items);
    const values = [];
    items.forEach((item) => {
      if (item !== 'All') {
        values.push(InitialStatuses[item]);
      }
    });
  };


  const onFromDateChange = (val) => {
    if (!dateFns.isValid(val)) {
      setApplyButtonEnabled(false);
      return false;
    }
    const formatted = dateFns.format(val, 'yyyy-MM-dd');
    const formattedWithUtcOffset = dateFns.format(add(val, { days: 1 }), 'yyyy-MM-dd');
    setFromDate(formattedWithUtcOffset);
    if (dateFns.isAfter(dateFns.parse(formatted, 'yyyy-MM-dd'), dateFns.parse(toDate, 'yyyy-MM-dd'))) {
      setApplyButtonEnabled(false);
      return false;
    }
    setApplyButtonEnabled(true);
    return true;
  };

  const onToDateChange = (val) => {
    if (!dateFns.isValid(val)) {
      setApplyButtonEnabled(false);
      return false;
    }
    const formatted = dateFns.format(val, 'yyyy-MM-dd');
    const formattedWithUtcOffset = dateFns.format(add(val, { days: 1 }), 'yyyy-MM-dd');
    setToDate(formattedWithUtcOffset);
    if (dateFns.isBefore(dateFns.parse(formatted, 'yyyy-MM-dd'), dateFns.parse(fromDate, 'yyyy-MM-dd'))) {
      setApplyButtonEnabled(false);
      return false;
    }
    setApplyButtonEnabled(true);
    return true;
  };


  const onApplyClick = () => {
    onApplyClicked(siteCodesFilter, serviceTypesFilter, selectedVendorsFilter, statusesFilter, selectedLocations, fromDate, toDate);
  };

  const onResetClick = () => {
    setSelectedSites(InitialSites);
    setSelectedServiceTypes(InitialServiceTypes);
    setSelectedLocations([]);
    setSelectedStatuses([]);
    setSelectedVendor('All');
    setFromDate(InitialFromDate);
    setToDate(InitialToDate);
    setApplyButtonEnabled(true);
  };

  return (
    <div className={classes.filterSection}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div style={{ textAlign: 'right', marginBottom: '20px' }}>
          <Button onClick={onResetClick}>
            <div style={{
              paddingBottom: '2px',
              borderBottom: '1px solid #366B8C',
              display: 'inline-block',
              color: '#366B8C',
              textTransform: 'none',
            }}
            >Reset All Filters
            </div>
          </Button>
        </div>

        <div style={{ marginBottom: '10px' }}>
          <Typography>Date Range</Typography>
        </div>
        <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
          <div style={{ width: '50px' }}>From</div>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="from-date"
            label=""
            value={fromDate}
            onChange={onFromDateChange}
            style={{ width: '150px' }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </div>
        <div style={{ display: 'flex', marginBottom: '4px', alignItems: 'center' }}>
          <div style={{ width: '50px' }}>To</div>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="to-date"
            label=""
            value={toDate}
            onChange={onToDateChange}
            style={{ width: '150px' }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </div>
        <div
          style={{ margin: '0 auto 16px auto', color: '#ED1C24', fontWeight: 'normal', display: (applyButtonEnabled ? 'none' : 'flex') }}
        >Invalid dates
        </div>
        <Divider className={classes.divider} />

        <div style={{ margin: '10px 0px' }}>
          <Typography>Sites</Typography>
        </div>
        <CheckGroup id="cbg-sites" list={Object.keys(InitialSites)} selectedItems={selectedSites} setSelectedItems={setSelectedSites} />
        <Divider className={classes.divider} />

        <div style={{ margin: '10px 0px' }}>
          <Typography>Service Type</Typography>
        </div>
        <CheckGroup id="cbg-service-types" list={Object.keys(InitialServiceTypes)} selectedItems={selectedServiceTypes} setSelectedItems={setSelectedServiceTypes} />
        <Divider className={classes.divider} />

        <div style={{ marginTop: '10px' }}>
          <Typography>Location</Typography>
        </div>
        <div style={{ width: '80%', marginTop: '-6px' }}>
          <MultiSelect
            id="select-locations"
            list={locationList}
            selectedItems={selectedLocations}
            setSelectedItems={onLocationChange}
            label="Select State"
          />
        </div>
        <Divider className={classes.divider} />
        <div style={{ marginTop: '10px' }}>
          <Typography>Status</Typography>
        </div>
        <div style={{ width: '80%', marginTop: '-6px' }}>
          <MultiSelect
            id="select-statuses"
            list={statusList}
            selectedItems={selectedStatuses}
            setSelectedItems={onStatusChange}
            label="Select Status"
          />
        </div>
        <Divider className={classes.divider} />
        { vendorsList.length > 1 &&
          (
            <>
              <div style={{ marginTop: '10px' }}>
                <Typography>Vendor</Typography>
              </div>
              <FormControl className={classes.select}>
                <Select
                  labelId="vendor-select-label"
                  id="vendor-select"
                  value={selectedVendor}
                  onChange={onVendorChange}
                >
                  <MenuItem key="0" value="All">All</MenuItem>
                  { vendorsList.map(item => (<MenuItem key={item.mvNumber} value={item.mvNumber}>{item.vendorName}</MenuItem>))}
                </Select>
              </FormControl>
              <Divider className={classes.divider} />
            </>
          )
        }
        <Button
          className={classes.applyButton}
          onClick={onApplyClick}
          disabled={!applyButtonEnabled}
        >
          Apply
        </Button>
      </MuiPickersUtilsProvider>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  onApplyClicked: PropTypes.func.isRequired,
};

export default Sidebar;
