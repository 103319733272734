import React, { useState } from 'react';
import { gql, NetworkStatus, useQuery, useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PrintIcon from '@material-ui/icons/Print';
import MarkAsReadIcon from '@material-ui/icons/Drafts';
import MarkAsUnreadIcon from '@material-ui/icons/Markunread';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { SET_JOB_HAS_BEEN_READ } from '../../apollo/mutations';
import {
  mutationArgWrapper,
} from '../../shared/utilities';
import {
  Error,
  PaginationTable,
  Loader,
  DateCell,
  UnscheduledOrderNumberCell,
  PrimaryPhoneNoCell,
  ServiceTypeCell,
  CustomerNameCell,
  AddressCell,
  ViewDetailsCell,
  SourceCell,
} from '../../shared/components';
import Dashboard from './Dashboard';
import styles from './styles';

const GET_UNSCHEDULED_JOBS = gql`
  query searchJobs($pageSize: Int!, $pageNumber: Int!, $sortField: String!, $sortDirection: String!, $jobStatuses: [String], $states: [String], $serviceTypes: [String], $siteCodes: [String], $sources: [String]) {
    searchJobs(
      pageSize: $pageSize,
      pageNumber: $pageNumber,
      sortField: $sortField,
      sortDirection: $sortDirection,
      includeFacets: true,
      jobStatuses: $jobStatuses,
      states: $states,
      serviceTypes: $serviceTypes,
      siteCodes: $siteCodes,
      sources: $sources
    ) {
      jobs {
        id
        customer {
          firstName
          lastName
          phones {
            number
          }
        }
        address {
          line1
          line2
          city
          state
          zipcode
        }
        aggregatedAttributes {
          oversizedVerticalFeet
        }
        order {
          orderNumber
          date
        }
        serviceType
        siteCode
        source
        hasJobBeenRead
      }
      paginationMetadata {
        currentPage
        pageSize
        totalPageCount
        totalItemCount
      }
      searchFacets {
        name
        searchFacetBuckets {
          key
          count
        }
      }
    }
  }
`;

const useStyles = makeStyles(styles);

const filtersConfig = [
  {
    label: 'Service Type',
    filterChoicesField: 'serviceType',
  },
  {
    label: 'Location',
    filterChoicesField: 'address.state',
  },
  {
    label: 'Sites',
    filterChoicesField: 'siteCode',
  },
  {
    label: 'Originated From',
    filterChoicesField: 'source',
  },
];

const tableConfig = [
  {
    id: 'date',
    columnName: 'Date',
    sortField: 'date',
    renderValue: job => <DateCell job={job} isBold={!job.hasJobBeenRead} />,
  },
  {
    id: 'orderNumber',
    columnName: 'Order',
    sortField: 'orderNumber',
    renderValue: job => <UnscheduledOrderNumberCell job={job} isBold={!job.hasJobBeenRead} />,
  },
  {
    id: 'serviceType',
    columnName: 'Type',
    sortField: 'serviceType',
    renderValue: job => <ServiceTypeCell job={job} />,
  },
  {
    id: 'source',
    columnName: 'Source',
    sortField: 'source',
    renderValue: job => <SourceCell job={job} />,
  },
  {
    id: 'name',
    columnName: 'Name',
    sortField: 'name',
    renderValue: job => <CustomerNameCell job={job} isBold={!job.hasJobBeenRead} />,
  },
  {
    id: 'location',
    columnName: 'Location',
    sortField: 'location',
    renderValue: job => <AddressCell job={job} isBold={!job.hasJobBeenRead} />,
  },
  {
    id: 'phones',
    columnName: 'Primary No.',
    sortField: 'phones',
    renderValue: job => <PrimaryPhoneNoCell job={job} isBold={!job.hasJobBeenRead} />,
  },
  {
    id: 'viewOrderDetails',
    columnName: '',
    renderValue: job => <ViewDetailsCell job={job} />,
  },
];

function getActionsConfig(setJobHasBeenReadMutation) {
  const setJobHasBeenReadMutationWrapped = mutationArgWrapper('setJobHasBeenRead', setJobHasBeenReadMutation);

  return [
    {
      title: 'Mark As Read',
      onClick: selected => (selected.length > 0) && setJobHasBeenReadMutationWrapped({ status: true, jobIds: selected }),
      materialUIIcon: <MarkAsReadIcon />,
    },
    {
      title: 'Mark As Unread',
      onClick: selected => (selected.length > 0) && setJobHasBeenReadMutationWrapped({ status: false, jobIds: selected }),
      materialUIIcon: <MarkAsUnreadIcon />,
    },
    {
      title: 'Print Page',
      onClick: () => window.print(),
      materialUIIcon: <PrintIcon />,
    },
  ];
}

function renderFilterChoices(choices) {
  return choices.map(({ isChecked, toggleChoice, label }) => (
    <FormControlLabel
      key={label}
      control={(
        <Checkbox
          checked={isChecked}
          onChange={() => toggleChoice(isChecked)}
          value={label}
        />
      )}
      label={label.toUpperCase()}
    />
  ));
}

function routeToFilter(filters, history) {
  const pathname = '/';

  const qs = filtersConfig.map(f => filters[f.label].map((x => `${f.label}[]=${x}`)).join('&'))
    .filter(x => x !== '')
    .join('&');

  const search = `?${qs}`;
  const historyParams = { pathname, search };

  history.push(historyParams);
}


function renderFilters(searchFacets, selectedSearchFilters, classes, history, resetCurrentPageNumber) {
  return [...searchFacets]
    .sort((a, b) => {
      const labelsArray = filtersConfig.map(x => x.label);
      return labelsArray.indexOf(a.name) - labelsArray.indexOf(b.name);
    })
    .map((searchFacet) => {
      const uiSearchFacetBuckets = searchFacet.searchFacetBuckets.map(searchFacetBucket => ({
        isChecked: selectedSearchFilters[searchFacet.name].includes(searchFacetBucket.key),
        toggleChoice: (isChecked) => {
          resetCurrentPageNumber();
          const updatedValuesToMatch = !isChecked ?
            [...selectedSearchFilters[searchFacet.name], searchFacetBucket.key] :
            selectedSearchFilters[searchFacet.name].filter(value => value !== searchFacetBucket.key);

          routeToFilter({ ...selectedSearchFilters, [searchFacet.name]: updatedValuesToMatch }, history);
        },
        label: `${searchFacetBucket.key} (${searchFacetBucket.count})`,
      }));

      return (
        <div className={classes.filterSection} key={searchFacet.name}>
          <Typography variant="subtitle1">{searchFacet.name}</Typography>
          <FormGroup>
            {renderFilterChoices(uiSearchFacetBuckets)}
          </FormGroup>
          <Divider />
        </div>
      );
    });
}

function parseQueryString(location) {
  const qs = queryString.parse(location.search, { arrayFormat: 'bracket' });
  const getQsFilter = field => (typeof qs[field] === 'undefined' ? [] : qs[field]);
  return filtersConfig
    .map(x => x.label)
    .reduce((accumulator, currentValue) => ({ ...accumulator, [currentValue]: getQsFilter(currentValue) }), {});
}

function UnscheduledJobs() {
  const classes = useStyles();
  // Paging
  const [pageSize, setPageSize] = useState(50);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [sortField, setSortField] = useState('date');
  const [sortDirection, setSortDirection] = useState('desc');
  const [selectedJobs, setSelectedJobs] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const selectedSearchFilters = parseQueryString(location);
  const { loading, error, data, fetchMore, networkStatus } = useQuery(GET_UNSCHEDULED_JOBS, {
    variables: {
      pageSize: pageSize,
      pageNumber: currentPageNumber,
      sortField: sortField,
      sortDirection: sortDirection,
      jobStatuses: ['pending-schedule'],
      states: selectedSearchFilters.Location,
      serviceTypes: selectedSearchFilters['Service Type'],
      siteCodes: selectedSearchFilters.Sites,
      sources: selectedSearchFilters['Originated From'],
    },
    notifyOnNetworkStatusChange: true,
  });

  const [setJobHasBeenReadMutation, { error: errorJobReadStatusUpdate }] = useMutation(SET_JOB_HAS_BEEN_READ, {
    errorPolicy: 'none',
    update(cache, { data: { setJobHasBeenRead } }) {
      setJobHasBeenRead.forEach((result) => {
        cache.modify({
          id: `Job:${result.id}`,
          fields: {
            hasJobBeenRead: () => result.hasJobBeenRead,
          },
        });
      });
    },
    onCompleted: () => {
      setSelectedJobs([]);
    },
  });

  const loadingMoreJobs = networkStatus === NetworkStatus.fetchMore;
  if (loading && !loadingMoreJobs && !data) {
    return (
      <div className={classes.loadingContainer}>
        <Loader />
      </div>
    );
  }

  if (error) { return (
    <Error
      error={error}
      message="Unable to load ready to schedule jobs list. Please try again."
    />
  ); }

  if (errorJobReadStatusUpdate) { return (
    <Error
      error={error}
      message="Unable to process job read status update request. Please try again."
    />
  ); }

  const {
    jobs,
    searchFacets,
    paginationMetadata,
  } = data.searchJobs;

  const resetCurrentPageNumber = () => {
    setCurrentPageNumber(1);
  };

  const handleCheckboxClick = (isChecked, id) => {
    if (isChecked) {
      setSelectedJobs(previousCheckedJobIds => [
        ...previousCheckedJobIds,
        id,
      ]);
    } else {
      setSelectedJobs(previousCheckedJobIds => previousCheckedJobIds.filter(jobId => jobId !== id));
    }
  };

  const handleSelectAllCheckboxesClick = (isChecked) => {
    if (isChecked) {
      setSelectedJobs(jobs.map(n => n.id));
    } else {
      setSelectedJobs([]);
    }
  };

  return (
    <Dashboard
      renderSidebar={() => (
        <div className={classes.filtersContainer}>
          {renderFilters(searchFacets, selectedSearchFilters, classes, history, resetCurrentPageNumber)}
        </div>
      )}
    >
      <PaginationTable
        title="Ready To Schedule"
        toolBarConfig={getActionsConfig(setJobHasBeenReadMutation)}
        tableConfig={tableConfig}
        data={jobs}
        currentPage={paginationMetadata.currentPage}
        pageSize={pageSize}
        totalItemCount={paginationMetadata.totalItemCount}
        onChangePage={(page) => {
          fetchMore({
            variables: {
              pageNumber: page,
            },
          });
          setSelectedJobs([]);
        }}
        sortField={sortField}
        sortDirection={sortDirection}
        onChangeSortDirection={setSortDirection}
        onChangeSortField={setSortField}
        onChangePageSize={setPageSize}
        isLoading={loadingMoreJobs || loading}
        hasCheckboxSelection
        selectedRows={selectedJobs}
        onCheckboxClick={handleCheckboxClick}
        onSelectAllCheckboxClick={handleSelectAllCheckboxesClick}
      />
    </Dashboard>
  );
}

export default UnscheduledJobs;
