import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

function getStyles(listItem, selectedItem, theme) {
  return {
    fontWeight:
    selectedItem.indexOf(listItem) === -1
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Multiselect = ({ selectedItems, setSelectedItems, list, label }) => {
  const classes = useStyles();
  const theme = useTheme();
  const handleChange = (event) => {
    setSelectedItems(event.target.value);
  };
  return (
    <div>
      <FormControl className={classes.formControl}>
        {label && <InputLabel id="multiple-chip-label"><Typography>{label}</Typography></InputLabel>}
        <Select
          labelId="multiple-chip-label"
          id="multiple-chip"
          multiple
          value={selectedItems}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected.map(value => (
                <Chip key={value} label={value} className={classes.chip} style={{ borderRadius: 5 }} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {list.map(listItem => (
            <MenuItem key={listItem} value={listItem} style={getStyles(listItem, selectedItems, theme)}>
              {listItem}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

Multiselect.propTypes = {
  list: PropTypes.array.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
  label: PropTypes.string,
};

Multiselect.defaultProps = {
  label: '',
};

export default Multiselect;
