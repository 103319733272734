import React from 'react';
import {
  func, string, number, array, bool, arrayOf, shape,
} from 'prop-types';
import {
  Paper, TablePagination, Table, TableBody, makeStyles, TableContainer,
} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Toolbar from '../Table/Toolbar';
import styles from './styles';
import Loader from '../Loader';
import Head from './Head';

const useStyles = makeStyles(styles);


const PaginationTable = (props) => {
  const {
    title,
    toolBarConfig,
    tableConfig,
    data,
    totalItemCount,
    currentPage,
    pageSize,
    pageSizeOptions,
    sortField,
    sortDirection,
    onChangePage,
    onChangePageSize,
    onChangeSortDirection,
    onChangeSortField,
    isLoading,
    hasCheckboxSelection,
    selectedRows,
    onCheckboxClick,
    onSelectAllCheckboxClick,
  } = props;

  const classes = useStyles();

  const toolBarConfigWithData = toolBarConfig.map(configItem => ({
    ...configItem,
    onClick: () => configItem.onClick(selectedRows),
  }));

  const handleSort = (event, property) => {
    const isAsc = sortField === property && sortDirection === 'asc';
    onChangeSortDirection(isAsc ? 'desc' : 'asc');
    onChangeSortField(property);
  };

  return (
    <Paper className={classes.root}>
      <Toolbar
        numSelected={selectedRows.length}
        title={title}
        actionsConfig={toolBarConfigWithData}
      />
      <TableContainer className={classes.tableContainer}>
        {isLoading && (
        <div className={classes.loadingOverlay}>
          <Loader />
        </div>
        )}
        <Table stickyHeader aria-label="sticky table" aria-labelledby="tableTitle">
          <Head
            tableConfig={tableConfig}
            sortField={sortField}
            hasCheckboxSelection={hasCheckboxSelection}
            onSelectAllClick={onSelectAllCheckboxClick}
            onRequestSort={handleSort}
            sortDirection={sortDirection}
            allSelected={selectedRows && data && selectedRows.length === data.length}
          />
          <TableBody>
            {data && data.map(job => (
              <TableRow
                hover
                key={job.id}
              >
                {hasCheckboxSelection && (
                <TableCell padding="checkbox" className="do-not-print">
                  <Checkbox checked={selectedRows.includes(job.id)} onClick={event => onCheckboxClick(event.target.checked, job.id)} />
                </TableCell>
                )}
                {tableConfig.map(config => (
                  <TableCell
                    key={config.id}
                  >
                    {config.renderValue(job)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalItemCount}
        page={currentPage < 1 ? 0 : currentPage - 1}
        rowsPerPage={pageSize}
        rowsPerPageOptions={pageSizeOptions}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={(event, newPage) => {
          onChangePage(newPage + 1);
        }}
        onChangeRowsPerPage={event => onChangePageSize(event.target.value)}
      />
    </Paper>
  );
};

PaginationTable.defaultProps = {
  toolBarConfig: [],
  data: [],
  totalItemCount: 0,
  currentPage: 0,
  pageSizeOptions: [5, 10, 25, 50],
  hasCheckboxSelection: false,
  selectedRows: [],
  onCheckboxClick: () => {},
  onSelectAllCheckboxClick: () => {},
};

PaginationTable.propTypes = {
  /** Title to be displayed above the table */
  title: string.isRequired,
  /** Option ToolBar configuration */
  toolBarConfig: arrayOf(shape({})),
  /** Configuration around columns for the table */
  tableConfig: arrayOf(shape({
    id: string.isRequired,
    columnName: string.isRequired,
    sortField: string,
    renderValue: func.isRequired,
  })).isRequired,
  /** The data to be used to created rows */
  data: array,
  /** Total number of items from query if paging was not done */
  totalItemCount: number,
  /** The currently selected page */
  currentPage: number,
  /** The pagination size */
  pageSize: number.isRequired,
  /** Available client side page sizes default: [5, 10, 25, 50] */
  pageSizeOptions: array,
  /** The field currently being sorted on */
  sortField: string.isRequired,
  /** The field current sort direction (asc/desc) */
  sortDirection: string.isRequired,
  /** Used to show the loading/spinner overlay when fetching paged results */
  isLoading: bool.isRequired,
  /**
   * Callback fired when the sort field is changed.
   *
   * @param {string} sortField The sort field.
   */
  onChangeSortField: func.isRequired,
  /**
   * Callback fired when the sort direction is changed.
   *
   * @param {string} sortDirection The sort direction.
   */
  onChangeSortDirection: func.isRequired,
  /**
   * Callback fired when the page is changed.
   *
   * @param {number} page The page new page.
   */
  onChangePage: func.isRequired,
  /**
   * Callback fired when the page size is changed.
   *
   * @param {number} pageSize The page size.
   */
  onChangePageSize: func.isRequired,
  /**
   * show/hide checkbox for each row and header to select single/multiple rows.
   */
  hasCheckboxSelection: bool,
  /**
   * An Array of id's of the rows that should have their checkbox ticked.
   */
  selectedRows: array,
  /**
   * Callback function that is called when a row's checkbox is ticked/unticked.
   *
   * @param {boolean} isChecked state of the row's checkbox.
   * @param {string} id id used to identify the row's checkbox.
   */
  onCheckboxClick: func,
  /**
   * Callback function that is called when the all row's checkbox is ticked/unticked.
   *
   * @param {boolean} isChecked state of the all rows checkbox.
   */
  onSelectAllCheckboxClick: func,
};

export default PaginationTable;
