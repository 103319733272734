import React, { useState } from 'react';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { NetworkStatus, useQuery } from '@apollo/client';
import { getAllOrderedJobs } from './queries';
import {
  InitialFromDate,
  InitialToDate,
} from './constants';

import {
  DateCell,
  UnscheduledOrderNumberCell,
  ServiceTypeCell,
  StatusCell,
  CustomerNameCell,
  AddressCell,
  PrimaryPhoneNoCell,
  ViewDetailsCell,
  SourceCell,
  Loader,
  Error, PaginationTable,
} from '../../shared/components';
import Dashboard from './Dashboard';
import Sidebar from './Sidebar';
import styles from './styles';

const tableConfig = [
  {
    id: 'date',
    columnName: 'Date',
    sortField: 'date',
    renderValue: job => <DateCell job={job} />,
  },
  {
    id: 'orderNumber',
    columnName: 'Order',
    sortField: 'orderNumber',
    renderValue: job => <UnscheduledOrderNumberCell job={job} isBold={false} />,
  },
  {
    id: 'serviceType',
    columnName: 'Type',
    sortField: 'serviceType',
    renderValue: job => <ServiceTypeCell job={job} />,
  },
  {
    id: 'source',
    columnName: 'Source',
    sortField: 'source',
    renderValue: job => <SourceCell job={job} />,
  },
  {
    id: 'status',
    columnName: 'Status',
    sortField: 'status',
    renderValue: job => <StatusCell job={job} />,
  },
  {
    id: 'name',
    columnName: 'Name',
    sortField: 'name',
    renderValue: job => <CustomerNameCell job={job} />,
  },
  {
    id: 'location',
    columnName: 'Location',
    sortField: 'location',
    renderValue: job => <AddressCell job={job} />,
  },
  {
    id: 'phones',
    columnName: 'Primary No.',
    sortField: 'phones',
    renderValue: job => <PrimaryPhoneNoCell job={job} />,
  },
  {
    id: 'viewOrderDetails',
    columnName: '',
    renderValue: job => <ViewDetailsCell job={job} />,
  },
];

const useStyles = makeStyles(styles);

const JobsList = () => {
  const classes = useStyles();
  const location = useLocation();
  // Paging
  const [pageSize, setPageSize] = useState(50);
  const [sortField, setSortField] = useState('date');
  const [sortDirection, setSortDirection] = useState('desc');
  // Sidebar Filters
  const [serviceTypes, setServiceTypes] = useState([]);
  const [siteCodes, setSiteCodes] = useState([]);
  const [mvNumbers, setMvNumbers] = useState([]);
  const [jobStatuses, setJobStatuses] = useState([]);
  const [states, setStates] = useState([]);
  const [fromDate, setFromDate] = useState(InitialFromDate);
  const [toDate, setToDate] = useState(InitialToDate);

  const { searchTerm } = queryString.parse(location.search);
  const title = searchTerm ? `Search Results for ${searchTerm}` : 'All Jobs';


  const { loading, error, data, fetchMore, networkStatus } = useQuery(getAllOrderedJobs(), {
    variables: {
      pageSize: pageSize,
      pageNumber: 1,
      sortField: sortField,
      sortDirection: sortDirection,
      searchString: searchTerm,
      mvNumbers: mvNumbers,
      jobStatuses: jobStatuses,
      states: states,
      serviceTypes: serviceTypes,
      siteCodes: siteCodes,
      fromDate: fromDate,
      toDate: toDate,
    },
    notifyOnNetworkStatusChange: true,
  });

  const loadingMoreJobs = networkStatus === NetworkStatus.fetchMore;
  if (loading && !loadingMoreJobs && !data) {
    return (
      <div className={classes.loadingContainer}>
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <Error
        error={error}
        message="Unable to process the jobs list. Please use the filter section to narrow your search and try again."
      />
    );
  }

  const {
    jobs,
    paginationMetadata,
  } = data.searchJobs;

  return (
    <div>
      <Dashboard
        left={(
          <Sidebar
            classes={classes}
            onApplyClicked={(newSiteCodes, newServiceTypes, newMvNumbers, newJobStatuses, newStates, newFromDate, newToDate) => {
              setSiteCodes(newSiteCodes);
              setServiceTypes(newServiceTypes);
              setMvNumbers(newMvNumbers);
              setJobStatuses(newJobStatuses);
              setStates(newStates);
              setFromDate(newFromDate);
              setToDate(newToDate);
              fetchMore({
                variables: {
                  pageNumber: 1,
                  serviceTypes: newServiceTypes,
                  siteCodes: newSiteCodes,
                  mvNumbers: newMvNumbers,
                  jobStatuses: newJobStatuses,
                  states: newStates,
                  fromDate: newFromDate,
                  toDate: newToDate,
                },
              });
            }}
          />
        )}
        right={(
          <PaginationTable
            title={title}
            tableConfig={tableConfig}
            data={jobs}
            currentPage={paginationMetadata.currentPage}
            pageSize={pageSize}
            totalItemCount={paginationMetadata.totalItemCount}
            onChangePage={(page) => {
              fetchMore({
                variables: {
                  pageNumber: page,
                },
              });
            }}
            sortField={sortField}
            sortDirection={sortDirection}
            onChangeSortDirection={setSortDirection}
            onChangeSortField={setSortField}
            onChangePageSize={setPageSize}
            isLoading={loadingMoreJobs || loading}
          />
        )}
      />
    </div>
  );
};

export default JobsList;
