import React, { useState } from 'react';
import {
  func,
  arrayOf,
  object,
  bool,
} from 'prop-types';
import { getDayOfYear, add } from 'date-fns';
import Calendar from 'react-calendar';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import withStyles from '@material-ui/core/styles/withStyles';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';

import { formatTimeRange } from '../../../../shared/utilities';
import { timeslotType } from '../../../../shared/types';
import './SelectAlternateTime.css';
import styles from './styles';
import { Loader } from '../../../../shared/components';

const getDayTimeslotsMap = availableTimeslots => availableTimeslots
  .reduce((dayTimeslotsMap, timeslot) => {
    const day = getDayOfYear(timeslot.dateStart);
    const dayTimeslots = dayTimeslotsMap[day] || [];

    return {
      ...dayTimeslotsMap,
      [day]: dayTimeslots.concat(timeslot),
    };
  }, {});

const renderCalendar = (selectedDate, setDate, classes) => {
  const minDate = new Date();
  const maxDate = add(new Date(), { months: 2 });

  return (
    <div className={classes.calendarWrapper}>
      <Calendar
        className={classes.calendar}
        onChange={setDate}
        value={selectedDate}
        minDate={minDate}
        maxDate={maxDate}
        prev2Label=""
        next2Label=""
        calendarType="US"
      />
    </div>
  );
};

const isSelectedTimeslot = (selectedTimeslot, start, end) => {
  if (!selectedTimeslot) return false;

  const { dateStart, dateEnd } = selectedTimeslot;

  return dateStart === start && dateEnd === end;
};

const formatTimeslot = (dateStart, dateEnd) => {
  if (!dateStart || !dateEnd) return null;

  return formatTimeRange(dateStart, dateEnd);
};

const renderWeekendSelection = () => (
  <div><br /><strong>No time slots available for selected date.</strong></div>
);

const renderTimeslots = (
  timeslots = [],
  selectedTimeslot,
  setTimeslot,
  classes,
) => timeslots.map(({ dateStart, dateEnd }) => {
  const isSelected = isSelectedTimeslot(selectedTimeslot, dateStart, dateEnd);
  const selectTimeslot = () => setTimeslot({ dateStart, dateEnd });

  return (
    <Button
      variant="outlined"
      key={dateStart}
      className={classes.timeslotButton}
      classes={{ root: isSelected ? classes.selected : '' }}
      fullWidth
      onClick={selectTimeslot}
    >
      {formatTimeslot(dateStart, dateEnd)}
    </Button>
  );
});

function renderDialogActions(
  selectAlternateTime,
  selectedTimeslot,
  classes,
  cancelDialog,
) {
  const selectTimeslot = () => {
    selectAlternateTime(selectedTimeslot);
  };
  const hasSelectedTimeslot = Boolean(selectedTimeslot);

  return (
    <DialogActions>
      <Button
        variant="text"
        onClick={cancelDialog}
        className={classes.cancelButton}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        onClick={selectTimeslot}
        disabled={!hasSelectedTimeslot}
        className={classes.confirmButton}
        color="primary"
        classes={{ label: classes.buttonLabel }}
      >
        Confirm Time
      </Button>
    </DialogActions>
  );
}

const SelectAlternateTime = ({
  selectAlternateTime,
  availableTimeslots,
  classes,
  cancelDialog,
  isSubmittingAppointment,
  isErrorForAppointmentDialog,
  setIsErrorForAppointmentDialog,
  preferredAppointmentTimes,
  hasAppointment,
}) => {
  const [selectedDate, setDate] = useState(new Date());
  const [selectedTimeslot, setTimeslot] = useState('');

  const dayTimeslotsMap = getDayTimeslotsMap(availableTimeslots);
  const selectedDayOfYear = getDayOfYear(selectedDate);
  const timeslotsForSelectedDate = dayTimeslotsMap[selectedDayOfYear];

  const resetErrorState = () => {
    setIsErrorForAppointmentDialog(false);
  };

  const closeDialog = () => {
    setIsErrorForAppointmentDialog(false);
    cancelDialog();
  };

  const renderDialogTitle = () => (
    <DialogTitle
      id="select-alternate-time"
      className={classes.title}
    >
      {preferredAppointmentTimes.length === 0 && !hasAppointment ? 'Select an arrival day and time' : 'Please select another time'}
    </DialogTitle>
  );

  if (isSubmittingAppointment) {
    return (
      <>
        {renderDialogTitle()}
        <DialogContent className={classes.wrapper}>
          <div className={classes.widthContainer}>
            <DialogContentText className={classes.contentText}>
                Please make sure you confirm this change with the customer.
            </DialogContentText>
            <div className={classes.circularSpinnerContainer}>
              <Loader />
              <div className={classes.circularSpinnerText}>
                Confirming Day and Time
              </div>
            </div>
          </div>
        </DialogContent>
        <div className={classes.footer} />
      </>
    );
  }

  if (isErrorForAppointmentDialog) {
    return (
      <>
        {renderDialogTitle()}
        <DialogContent className={classes.wrapper}>
          <div className={classes.widthContainer}>
            <DialogContentText className={classes.contentText}>
                Please make sure you confirm this change with the customer.
            </DialogContentText>
            <div className={classes.errorMessageContainer}>
              <ErrorIcon color="error" />
              <DialogContentText className={classNames(classes.contentText, classes.errorMessageText, classes.confirmWarning)}>
                <p>An unexpected error has occurred.</p>
                <p>Please try again.</p>
              </DialogContentText>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={closeDialog}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={resetErrorState}
            className={classes.confirmButton}
            color="primary"
            classes={{ label: classes.buttonLabel }}
          >
             TRY AGAIN
          </Button>
        </DialogActions>
      </>
    );
  }

  return (
    <>
      {renderDialogTitle()}
      <DialogContent className={classes.wrapper}>
        <div className={classes.widthContainer}>
          <DialogContentText className={classes.contentText}>
            Please make sure you confirm this change with the customer.
          </DialogContentText>
          {renderCalendar(selectedDate, setDate, classes)}
          {timeslotsForSelectedDate === undefined && renderWeekendSelection()}
          {renderTimeslots(timeslotsForSelectedDate, selectedTimeslot, setTimeslot, classes)}
          <DialogContentText className={classes.confirmWarning}>
            Confirming the appointment time will send an email to the customer.
          </DialogContentText>
        </div>
      </DialogContent>
      {renderDialogActions(selectAlternateTime, selectedTimeslot, classes, cancelDialog)}
    </>
  );
};

SelectAlternateTime.propTypes = {
  selectAlternateTime: func.isRequired,
  availableTimeslots: arrayOf(timeslotType),
  classes: object.isRequired,
  cancelDialog: func.isRequired,
  isErrorForAppointmentDialog: bool.isRequired,
  isSubmittingAppointment: bool.isRequired,
  setIsErrorForAppointmentDialog: func.isRequired,
  preferredAppointmentTimes: arrayOf(timeslotType),
  hasAppointment: bool,
};

SelectAlternateTime.defaultProps = {
  availableTimeslots: [],
  preferredAppointmentTimes: [],
  hasAppointment: false,
};

export default withStyles(styles)(SelectAlternateTime);
